<template>
	<!-- 汇总 -->
	<z-plate title="查看评分汇总" has-bottom-border>
		<!-- title 左侧 -->
		<template #titleLeft>
			<el-form inline>
				<el-form-item>
					<el-input v-model="form.schoolName" placeholder="学校名称" />
				</el-form-item>

				<el-form-item>
					<el-input v-model="form.videoName" placeholder="作品名称" />
				</el-form-item>

				<el-form-item>
					<el-select v-model="form.channelId" placeholder="请选择作品分类">
						<el-option v-for="item in options" :key="item.channelId"
							:label="item.groupName + ' - ' + item.channelName" :value="item.channelId"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item >
                    <el-input v-model="form.randomCode" placeholder="视频编码" clearable maxlength="10" style="width:150px;" />
                </el-form-item>

				<el-form-item>
					<el-button icon="el-icon-search" @click="queryList">搜索</el-button>
				</el-form-item>
			</el-form>
		</template>

		<!-- title 右侧按钮 -->
		<template #titleRight>
			<el-button @click="exportAllData"> <img src="../../assets/img/icon-export.png" alt class="icon" />导出全部
			</el-button>
			<el-button @click="exportData"> <img src="../../assets/img/icon-export.png" alt class="icon" />导出
			</el-button>
			<el-button type="primary" @click="turnTuKu"> 推荐库 </el-button>
		</template>

		<!-- 表格 -->
		<el-table class="z-table" :data="tableData.content" v-if="tableData.content.length" :cell-style="setCellStyle"
			v-loading="tableLoading" style="width: 100%">
			<el-table-column type="selection" width="60"></el-table-column>

			<el-table-column type="index" label="序号" width="60"></el-table-column>

			<el-table-column prop="channelName" label="作品分类"></el-table-column>

			<el-table-column prop="randomCode" label="视频编码" width="80"></el-table-column>

			<el-table-column prop="videoName" label="作品名称">
				<template slot-scope="{ row }">
					<span class="color-primary cursor"
						@click="checkWorks(row.videoId, row.videoType, row.videoStatus, row.videoUrl, row.videoName)">{{
								row.videoName
						}}</span>
				</template>
			</el-table-column>

			<el-table-column prop="schoolName" label="上报单位" show-overflow-tooltip></el-table-column>

			<el-table-column label="平均分" width="90">
				<template slot-scope="{ row }">
					<strong class="color-primary" v-if="row.avgScore > 0">{{ row.avgScore }}</strong>
					<strong class="color-primary" v-else> - </strong>
				</template>
			</el-table-column>

			<el-table-column :label="item.teacherName" width="90" :key="index1"
				v-for="(item, index1) in tableData.content[0].judgeList">
				<template slot-scope="{ row }">
					<strong class="color-primary"
						v-if="row.judgeList[index1].totalScore != '' && row.judgeList[index1].totalScore > 0">{{
								row.judgeList[index1].totalScore
						}}</strong>
					<strong class="color-primary" style="color:crimson;" v-else-if="row.judgeList[index1].totalScore == null "> x </strong>
					<strong class="color-primary" v-else > - </strong>
				</template>
			</el-table-column>

			<el-table-column prop="address" label="操作" width="260" fixed="right">
				<template slot-scope="{ row }">
					<el-button @click="findDetail(row.videoId)">评分明细</el-button>
					<el-button @click="addTuiJianKu(row.videoId, '0')" v-if="row.recommendFlag == 1">取消推荐</el-button>
					<el-button @click="addTuiJianKu(row.videoId, '1')" v-else>加入推荐库</el-button>
				</template>
			</el-table-column>
		</el-table>

		<v-nodata v-else></v-nodata>

		<!-- 分页 -->
		<z-pagination v-if="pagination.totalPages > 0" :page.sync="pagination.page" :limit.sync="pagination.size"
			:total="pagination.totalPages" @pagination="init"></z-pagination>

		<v-check-works v-if="dialogVisible" :show.sync="dialogVisible" :data="dialogData"></v-check-works>
	</z-plate>
</template>

<script>
import VCheckWorks from './CheckWorks'
export default {
	components: {
		VCheckWorks
	},
	data() {
		return {
			form: {
				schoolName: "",
				videoName: "",
				channelId: "",
				randomCode: ""
			},
			options: [],
			tableData: {
				content: []
			},
			pagination: {
				page: 0, //当前页
				limit: 10, //分页条数
				total: 0 //总页数
			},
			tableLoading: false,
			activityId: sessionStorage.getItem("group_activityid"),
			dialogData: {},
			dialogVisible: false
		};
	},

	mounted() {
		this.queryChannelList();
	},

	methods: {
		init() {
			let { pagination } = this;
			this.tableLoading = true;
			this.$post('/queryActivityCollect', {
				activityid: this.activityId,
				channelId: this.form.channelId,
				videoName: this.form.videoName,
				schoolName: this.form.schoolName,
				randomCode: this.form.randomCode,
				page: pagination.page,
				size: pagination.size
			}).then(rep => {
				this.tableData = rep.content.page;
				pagination.page = this.tableData.number;
				pagination.totalPages = this.tableData.totalElements;
				this.tableLoading = false;
			})

		},

		queryList() {
			this.pagination.page = 0;
			this.init();
		},

		queryChannelList() {
			this.$post("/queryChannelById", {
				activityId: this.activityId
			}).then(m => {
				if (m.resultCode == 0) {
					this.options = m.content.list;
					this.form.channelId = this.options[0].channelId;
					this.queryList();
				}
			})
		},

		//videoUrl 视频地址
		checkWorks(videoid, type, status, videoUrl, videoName) {
			this.dialogData = {}
			this.$nextTick(() => {
				if (type == 0) { //视频类
					if (status != 3) {
						this.$message({
							type: 'info',
							message: '视频正在转码中'
						});
						return;
					} else {
						this.dialogData = {
							type: 0,
							url: videoUrl,
							name: videoName
						}
						this.dialogVisible = true;
					}
				} else if (type == 1) { //图片类
					this.dialogVisible = true;
					this.loadPicListByVideoId(videoid).then(rep => {
						let videoname = videoName;
						if (rep.content.picList.length > 1) {
							videoname += "（组图）";
						}

						this.dialogData = {
							type: 1,
							url: rep.content.picList,
							name: videoname
						}
					});
				}
			})
		},

		loadPicListByVideoId(videoid) {
			return this.$post('/loadPicListByVideoId', {
				"videoid": videoid
			});
		},

		addTuiJianKu(videoId, flag) {
			let recommendFlag;
			if (flag == '1') {
				recommendFlag = 1;
			} else {
				recommendFlag = 0;
			}
			//				console.log(videoId,recommendFlag);
			this.$post("/changeActivityRecommend", {
				videoId: videoId,
				recommendFlag: recommendFlag
			}).then(m => {
				if (m.resultCode == 0) {
					this.$message({
						type: 'success',
						message: '操作成功！'
					});

					this.pagination.page = 0;
					this.init();
				}
			})

		},

		exportData() {
			if (!this.tableData.content.length) {
				this.$message({
					type: 'info',
					message: '暂无导出数据信息'
				});
				return;
			}
			window.open("api/exportCollectByActivityId?activityid=" + this.activityId + "&channelId=" + this.form.channelId + "&videoName=" + this.form.videoName + "&schoolName=" + this.form.schoolName + 
			"&randomCode=" + this.form.randomCode + "&access_token=" + sessionStorage.getItem("ACCESS_TOKEN"));
		},

		exportAllData() {
			window.open("api/exportAllCollectByActivityId?activityid=" + this.activityId + "&access_token=" + sessionStorage.getItem("ACCESS_TOKEN"));
		},

		turnTuKu() {
			sessionStorage.setItem("group_activityid", this.activityId);
			this.$router.push({ name: 'AppraiseRecommend' });
		},

		findDetail(videoId) {
			sessionStorage.setItem("videoId", videoId);
			this.$router.push({ name: 'AppraiseSummarizeDetail' });
		},

		//分数悬殊大的一行背景色标注
		setCellStyle({ row, column, rowIndex, columnIndex }) {
			if (row.isRed) {
				return "background-color: #ff000047";
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
